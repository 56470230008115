<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    height="height"
    viewBox="0 0 120.86 122.88"
  >
    <path
      :style="iconStyle"
      d="M120.86,70.47H96.44v43.46h24.42V70.47ZM91.52,110.28V74H75.19c-6.93,1.24-13.85,5-20.78,9.36H41.73c-5.75.34-8.75,6.16-3.18,10,4.46,3.26,10.32,3.08,16.34,2.54,4.15-.21,4.33,5.37,0,5.39-1.5.12-3.14-.24-4.56-.24-7.51,0-13.7-1.44-17.49-7.37l-1.9-4.44L12.07,79.83C2.62,76.72-4.09,86.6,2.87,93.47a247.56,247.56,0,0,0,42,24.74c10.41,6.33,20.83,6.12,31.24,0l15.38-7.93ZM59.46,6.5,70.57,18.19H59.46V6.5ZM22.92,7.82H20.43a3,3,0,0,0-3.05,3V67.63a3,3,0,0,0,.89,2.15,3,3,0,0,0,2.16.9h41.8a3.06,3.06,0,0,0,3.05-3.05V64.82H61.87v2.45H20.79V11.21h2.13V7.82ZM36.79,52H66.65V49.16H36.79V52Zm0-8.76H66.65V40.4H36.79v2.86Zm0-26.26h10V14.14h-10V17Zm0,8.75H50.91V22.89H36.79v2.86Zm0,8.75H67V31.64H36.79V34.5Zm40-14.82a1.87,1.87,0,0,0-1.35-1.79L59,.68A1.84,1.84,0,0,0,57.61,0H30.32A3.31,3.31,0,0,0,27,3.33V58a3.31,3.31,0,0,0,3.32,3.33H73.43A3.31,3.31,0,0,0,76.75,58V19.68ZM73,21.9V57.57H30.71V3.7h25V20a1.87,1.87,0,0,0,1.87,1.86Z"
      transform="translate(0 -2.499)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '15.801',
    },
    width: {
      type: String,
      default: '30.146',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
